import React from "react";
import styles from "./LiveSession.module.css";
import { Col, Container, Row } from "react-bootstrap";
import LiveImage from "../../../Assets/live-course-image.png";
import liveVideo from "../../../Assets/live-course-video.png";
import { Link } from "react-router-dom";

const LiveSession = () => {
  return (
    <Container className={styles.container} fluid>
      <Row>
        <Col lg={2}> </Col>
        <Col lg={8}>
          <p className={styles.header_text}>
            join a{" "}
            <span style={{ color: "red", textTransform: "uppercase" }}>
              live{" "}
            </span>{" "}
            masterclass{" "}
          </p>

          <Row>
            <Col lg={6} className="px-3">
              <div className={styles.first_img}>
                <img src={LiveImage} alt="" />
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <img src={liveVideo} alt="" />
                <Link to="" className={styles.watch_link}>
                  watch
                </Link>
              </div>
            </Col>

            <Col className={styles.text_end_layout}>
              <h1 className={styles.text_end}>
                Join us on any of our
                <br />
                <span style={{ textTransform: "uppercase", color: "red" }}>
                  masterclass
                </span>{" "}
                for engaging <br /> and Premium content
              </h1>
            </Col>
          </Row>
        </Col>
        <Col lg={2}> </Col>
      </Row>
    </Container>
  );
};

export default LiveSession;
