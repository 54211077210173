import axios from "axios";
import { useState } from "react";
const base_url = "https://empyrean.washrytelaundry.com.ng/api/v1/videos";

export const useFetchData = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const getEpisodes = async () => {
    setIsLoading(true);
    axios
      .get(base_url)
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  };
  return { data, isLoading, error, getEpisodes, };
};
