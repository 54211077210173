import React from "react";
import { useState } from "react";
import { Button, Modal, Container, Row, Col } from "react-bootstrap";
import styles from "./ModalPopUp.module.css";
import product from "../../../Assets/Product-Hero-Image.png";

const ModalPopUp = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      <Button variant="primary" onClick={handleShow}>
        Add To Cart
      </Button>

      <Modal show={show} onHide={handleClose} className={styles.modal} style={{width:"100-vw"}} size="lg">
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body >
          <Container className={styles.container} fluid >
            <Col className="mx-auto">
              <div>
                <img src={product} alt="" className={styles.product_img} />
              </div>
              <div>
                <img
                  src={product}
                  alt=""
                  className={styles.product_img}
                  style={{ width: "50px" }}
                />
                <img
                  src={product}
                  alt=""
                  className={styles.product_img}
                  style={{ width: "50px" }}
                />
                <img
                  src={product}
                  alt=""
                  className={styles.product_img}
                  style={{ width: "50px" }}
                />
              </div>
            </Col>
            <Col className="mx-auto">
              <h1>sweat shirts</h1>
              <p>100% pure cotton unisex sweat shirt</p>
              <div className="buttons">
                <button className={styles.btn}>2XL</button>
                <button className={styles.btn}>XL</button>
                <button className={styles.btn}>L</button>
                <button className={styles.btn}>M</button>
                <button className={styles.btn}>S</button>
              </div>
              <div className="type">
                <p>Color:</p>
                <select name="" id="">
                  <option value="Color">Color</option>
                  <option value="Red">RED</option>
                  <option value="White">BLUE</option>
                  <option value="Blue">YELLOW</option>
                </select>
              </div>
              <div className="quntity">
                <p>Quantity:</p>
                <input type="number" name="" id="" style={{width:"60px"}} />
              </div>
            </Col>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Add to cart
          </Button>
          <Button variant="primary" onClick={handleClose}>
            checkout
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalPopUp;
