import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CoupleVideo from "../../../Assets/couplevideo.png";
import styles from "./Hero.module.css";
import EmpyreanBooklet from "../../../Assets/empyrean booklet 1 (1).png";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <Container className={styles.container}>
      <Row>
        <Col></Col>
        <Col className="d-flex">
          <Row>
            <p Book className={styles.header_text}>
              Book a live consultation
            </p>

            <div className={`text-center  ${styles.video_container}`}>
              <div
                className={`bg-white rounded-3 mx-auto mt-4 ${styles.alert_layout}`}>
                <p
                  className={`text-black font-family-Poppins m-0 px-3 py-2 ${styles.alert_text}`}>
                  The Empyrean couple
                </p>
              </div>
              <div className="mt-4">
                <img
                  src={CoupleVideo}
                  alt="couplevideo"
                  className={styles.couple_video}
                />
              </div>
              <div className=""></div>
              <div></div>
            </div>
            <div className={styles.bookings}>
              <span className="text-black fs-4 fw-medium font-family-Poppins   text-uppercase">
                book now
              </span>
              <span>
                <a
                  href="https://tidycal.com/drenills7academy/meet-the-empyrean-couple"
                  target="_blank"
                  rel="noopener noreferrer">
                  <i className="fa-solid fa-arrow-right"></i>
                </a>
              </span>
            </div>
          </Row>
        </Col>
        <Col>
          <Row>
            <p Book className={styles.header_text}>
              get our best seller e-book
            </p>

            <div className={`text-center ${styles.book_container}`}>
              <img
                src={EmpyreanBooklet}
                alt=""
                className={`mx-auto ${styles.booklet}`}
              />
              <a
                href="https://www.amazon.ca/dp/B0CQ11Z2NP"
                to=""
                className={styles.preview_link}>
                Preview
              </a>
            </div>
          </Row>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
};

export default Hero;
