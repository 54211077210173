import { Fragment } from "react";
import Hero from "../components/HomeComponents/Hero/Hero";
import Episode from "../components/HomeComponents/Hero/Episode";
import ProductList from "../components/HomeComponents/Products/ProductList";
import Testimonials from "../components/HomeComponents/Testimonials/Testimonials";
import Memories from "../components/HomeComponents/Memories/Memories";

const HomePage = () => {
  return (
    <div style={{ overflow: "hidden" }}>
      <Hero />
      <Episode />
      <ProductList />
      <Testimonials />
      <Memories />
    </div>
  );
};

export default HomePage;
