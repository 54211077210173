import React from 'react'
import Hero from '../components/PremiumServiceComponent/Hero/Hero'
import LiveSession from '../components/PremiumServiceComponent/LiveSession/LiveSession'
import CoupleCourses from '../components/PremiumServiceComponent/CoupleCourses/CoupleCourses'
import OurEpisodes from '../components/PremiumServiceComponent/OurEpisodes/OurEpisodes'

const PremiumServicesPage = () => {
  return (
    <div>
      <Hero/>
      <LiveSession/>
      <CoupleCourses/>
      <OurEpisodes/>
    </div>
  )
}

export default PremiumServicesPage
