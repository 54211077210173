import { useState, useEffect } from "react";
import axios from "axios";

const usePagination = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [recordsPerPage] = useState(9);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [nextDataUrl, setNextDataUrl] = useState("");
  const [prevDataUrl, setPrevDataUrl] = useState("");
  const [totalPaginationPage, setTotalPaginationPage] = useState(1);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  // get products data on page render
  const getAllProducts = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/products`);
      setIsLoading(false);
      setNextDataUrl(response?.data?.pagination?.links.next);
      setPrevDataUrl(`${baseUrl}/products?page=1`);
      setTotalPaginationPage(response?.data?.pagination.totalPages);
      return setProducts(response?.data?.data);
    } catch (error) {
      setIsLoading(false);
      return setErrorMessage("something went wrong");
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  // get next page products data function
  const getNextData = async () => {
    const response = await axios.get(`${nextDataUrl}`);
    setPageCount(pageCount + 1);
    setPrevDataUrl(`${baseUrl}/products?page=${pageCount}`);
    return setProducts(response?.data?.data);
  };

  // fetch previous products data
  const getPreviousData = async () => {
    const response = await axios.get(`${prevDataUrl}`);
    setPageCount(pageCount - 1);
    setNextDataUrl(`${baseUrl}/products?page=${pageCount}`);
    return setProducts(response?.data?.data);
  };

  // fetch products data when click on page numbers
  const pageNumFetch = async (dataPageNum) => {
    const response = await axios.get(`${baseUrl}/products?page=${dataPageNum}`);
    return setProducts(response?.data?.data);
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const currentRecords =
    products.slice(indexOfFirstRecord, indexOfLastRecord) || [];
  const nPages = Math.ceil((products.length || 0) / recordsPerPage);

  const pageNumbers = [...Array(totalPaginationPage + 1).keys()].slice(1);

  // next page click handler
  const nextPage = (event) => {
    event.preventDefault();
    if (totalPaginationPage > pageCount) {
      return getNextData();
    }
  };

  //previous page click handler
  const prevPage = (event) => {
    event.preventDefault();
    if (totalPaginationPage <= pageCount) {
      return getPreviousData();
    }
  };

  return {
    currentRecords,
    pageNumbers,
    nPages,
    errorMessage,
    isLoading,
    pageCount,
    nextPage,
    prevPage,
    pageNumFetch,
    getAllProducts,
  };
};

export default usePagination;
