import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { productApi } from "../lib/API/productApi";



export const store = configureStore({
    reducer: {
        [productApi.reducerPath]: productApi.reducer
    }, 

    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware().concat(
            productApi.middleware
        )
    
})

setupListeners(store.dispatch)