import React from "react";
import styles from "./EpisodeCards.module.css";
import { Card } from "react-bootstrap";
import YouTube from "react-youtube";

const EpisodeCards = ({ episode }) => {
  const videoId = extractVideoId(episode?.url);

  return (
    <div className="">
      <Card className={styles.product_card}>
        <YouTube videoId={videoId} opts={{ width: 353, height: 364 }} className={styles.video}/>
        <Card.Body
          className={`text-center`}
          style={{ backgroundColor: "#f51133", color: "white" }}
        >
          <Card.Title className={styles.title}>{episode?.title}</Card.Title>
          <Card.Text className={styles.description}>
          </Card.Text>
          <div className="">
            <a href={episode?.url} className={styles.watch_btn}>watch now</a>
          </div>
        </Card.Body>
        <div className="text-end d-flex justify-content-center">
        </div>
      </Card>
    </div>
  );
};

// Function to extract YouTube video ID from the URL
const extractVideoId = (url) => {
  const match = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
  return match ? match[1] : null;
};

export default EpisodeCards;
