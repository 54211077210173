import React from "react";
import styles from "./CoupleCourses.module.css";
import { Container, Col, Row, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import ChildBirthVideo from "../../../Assets/child-birth-video.png";
import Ellipse from "../../../Assets/Ellipse 10.png";

const CoupleCourses = () => {
  const cardData = [
    {
      title: "One-On-One Sessions",
      description:
        "Sometimes, couples just need someone to listen to their concerns and challenges.",
    },
    {
      title: "Speaking Engagements",
      description:
        "Sometimes, couples just need someone to listen to their concerns and challenges.",
    },
  ];

  return (
    <Container className={styles.container}>
      <Row>
        <Row>
          <p className={styles.text_heading}>courses for couples</p>
          <Col></Col>
          <Col className="">
            <img src={ChildBirthVideo} alt="" />
          </Col>
          <Col>
            <div className={styles.video_text_layout}>
              <p className={styles.video_text}>
                Love is the universal language that binds us all. We celebrate
                love in all its forms. We offer advice,
                <br /> support, and inspiration to foster healthy
                <br /> relationships.
              </p>
              <Link to="" className={styles.watch_link}>
                watch
              </Link>
            </div>
          </Col>
          <Col></Col>
        </Row>
        <Row className="mt-5">
          <Col></Col>
          <Col className="">
            <img src={ChildBirthVideo} alt="" />
          </Col>
          <Col>
            <div className={styles.video_text_layout}>
              <p className={styles.video_text}>
                Love is the universal language that binds us all. We celebrate
                love in all its forms. We offer advice,
                <br /> support, and inspiration to foster healthy
                <br /> relationships.
              </p>
              <Link to="" className={styles.watch_link}>
                watch
              </Link>
            </div>
          </Col>
          <Col></Col>
        </Row>
        <Col lg={3}></Col>

        <Col lg={6} className="pt-5">
          <h1 className="text-center mt-5">Our Premium Services</h1>
          <Col></Col>
          <Col className={styles.card_layout}>
            {cardData.map((item, index) => (
              <CoupleCourseCard
                key={index}
                title={item.title}
                description={item.description}
              />
            ))}
          </Col>
        </Col>
        <Col lg={3}></Col>
      </Row>
    </Container>
  );
};

const CoupleCourseCard = ({ title, description }) => (
  <Container>
    <Col></Col>
    <Col>
      <Card className={`rounded-4 col-10 py-3  ${styles.card}`}>
        <Card.Img
          className="rounded-circle border border-3 border-white col-7 mx-auto"
          src={Ellipse}
          alt={title}
          style={{ width: "180px" }}
        />
        <Card.Body className="text-center">
          <Card.Title className="text-black fs-3 fw-medium font-family-Poppins text-capitalize col-12 m-0 px-3 py-2">
            {title}
          </Card.Title>
          <Card.Text className="text-black fs-6 fw-light font-family-Poppins text-capitalize col-12 m-0 px-1 py-2">
            {description}
          </Card.Text>
          <div
            className={`mx-auto py-1 bg-white d-flex justify-content-center align-items-center col-6 ${styles.button_layout}`}>
            <a
              href="https://tidycal.com/drenills7academy/meet-the-empyrean-couple"
              target="_blank"
              rel="noopener noreferrer"
              className="text-black fs-6 fw-medium font-family-Poppins m-0 px-1 py-1 ">
              Book A Session
            </a>
          </div>
        </Card.Body>
      </Card>
    </Col>
    <Col></Col>
  </Container>
);

export default CoupleCourses;
