import React from 'react';
import styles from './Pagination.module.css';

const Pagination = ({ npages, currentPage, setCurrentPage }) => {
  // const pageNumbers = [...Array(totalPages + 1).keys()].slice(1);
  const pageNumbers = Array.from({ length: npages }, (_, index) => index + 1);


  return (
    <ul className={styles.episodes_pagination}>
      {pageNumbers.map((pageNumber) => (
        <li key={pageNumber} className={styles.episodes_pagination}>
          <button
            className={`${styles.pag_button} ${currentPage === pageNumber ? 'active' : ''}`}
            onClick={() => setCurrentPage(pageNumber)}
          >
            {pageNumber}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default Pagination;
