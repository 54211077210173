import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"

const baseUrl = process.env.REACT_APP_BASE_URL

export const productApi = createApi({
    reducerPath:"productApi", 
    baseQuery: fetchBaseQuery({
        baseUrl
    }),
    endpoints: (builder) => ({
        getAllProducts: builder.mutation({
            query:() => ({
                url:"/products",
                method:"GET",
            })
        })
    })
})


export const {useGetAllProductsMutation} = productApi