// FeaturedProducts.js
import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Container, Card, Button, Spinner } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import styles from "./FeaturedProducts.module.css";
import star from "../../../Assets/Star 1 (1).png";
import { useGetAllProductsMutation } from "../../../lib/API/productApi";

const FeaturedProducts = () => {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 767px)" });

  const [getAllProducts, { data }] = useGetAllProductsMutation();

  useEffect(() => {
    getAllProducts();
  }, [getAllProducts]);

  const handleImageError = (e) => {
    e.target.src = "error-placeholder.jpg"; // Replace with the correct path for an error placeholder image
  };

  const loadingPlaceholder = "loading-placeholder.jpg"; // Replace with the correct path for a loading placeholder image

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: isSmallScreen ? 1 : 3,
    slidesToScroll: 1,
  };

  return (
    <div className={`py-5 ${styles.container}`}>
      <Container className="w-75 m-auto mt-5">
        <Slider {...settings}>
          {data?.data.map((product, index) => (
            <div
              key={index}
              className={isSmallScreen ? styles.bodySmall : styles.body}
            >
              <Card
                className={`bg-white col-12 rounded-xl ${
                  isSmallScreen ? styles.smallcard : styles.card
                }`}
              >
                <div>
                  <img
                    src={product.media[0].url}
                    alt={`Product: ${product.name}`}
                    className={`hidden ${styles.product_image}`}
                    onLoad={(e) => {
                      e.target.classList.remove("hidden");
                    }}
                    onError={handleImageError}
                  />
                </div>
                <Card.Body className="d-flex flex-column justify-content-center align-items-center p-4">
                  <Card.Title
                    className={`text-xl font-semibold text-center ${
                      isSmallScreen ? styles.smalltitle : ""
                    }`}
                  >
                    {product.name}
                  </Card.Title>
                  <Card.Text
                    className={`${
                      isSmallScreen
                        ? styles.smallScreen_text
                        : "text-center fs-6"
                    }`}
                  >
                    {product.description}
                  </Card.Text>
                  <Card.Text>
                    <img src={star} alt="" style={{ display: "inline" }} />
                    <img src={star} alt="" style={{ display: "inline" }} />
                    <img src={star} alt="" style={{ display: "inline" }} />
                    <img src={star} alt="" style={{ display: "inline" }} />
                  </Card.Text>
                  <Button className={`px-4 py-2 rounded-xl ${styles.btn}`}>
                    ADD TO CART
                  </Button>
                </Card.Body>
              </Card>
            </div>
          ))}
        </Slider>
      </Container>
    </div>
  );
};

export default FeaturedProducts;
