import React, { useEffect, useState } from "react";
import { Container, Row, Alert, Spinner } from "react-bootstrap";
import ProductCard from "./ProductCard";
import navBack from "../../../Assets/chevrons-down.png";
import navNext from "../../../Assets/chevrons-left.png";
import styles from "./ProductPagination.module.css";
import { NavLink } from "react-router-dom";
import usePagination from "../../../hooks/usePagination";

const ProductList = () => {
  const {
    currentRecords,
    errorMessage,
    isLoading,
    nPages,
    pageNumbers,
    pageNumFetch,
    nextPage,
    prevPage,
  } = usePagination();

  const onGetProductsData = async (event) => {
    event.preventDefault();
    const pageNum = event.target.getAttribute("id");
    await pageNumFetch(pageNum);
  };

  return (
    <Container className={`mb-5 ${styles.product}`}>
      <Row className="justify-content-center align-items-center">
        {isLoading && (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
      </Row>
      <Row>
        {errorMessage && (
          <Alert variant="danger" className="text-center">
            <Alert.Heading>Error!</Alert.Heading>
            <p>{errorMessage}</p>
          </Alert>
        )}
        {!isLoading && !errorMessage && (
          <>
            <h2 className="text-center mt-5 mb-5">Our Products</h2>
            {currentRecords.map((product) => (
              <ProductCard
                key={product.id}
                name={product.name}
                description={product.description}
                price={product.price}
                ratings={product.ratings}
                imagePath={product.media[0].url}
              />
            ))}
          </>
        )}
      </Row>
      {nPages > 0 && !isLoading && !errorMessage && (
        <nav>
          <ul className="pagination justify-content-center">
            <li className={styles.pag_link}>
              <a href="#" className="" to="#" onClick={prevPage}>
                <img src={navBack} className={styles.next_img} alt="" />
              </a>
            </li>
            {pageNumbers.map((pgNum) => {
              return (
                <li key={pgNum} className={styles.pag_link}>
                  <NavLink
                    id={pgNum}
                    className={styles.pag_numbers}
                    to="#"
                    onClick={onGetProductsData}
                  >
                    {pgNum}
                  </NavLink>
                </li>
              );
            })}
            <li className={styles.pag_link}>
              <a href="#" className="" onClick={nextPage} to="#">
                <img src={navNext} alt="" className={styles.next_img} />
              </a>
            </li>
          </ul>
        </nav>
      )}
    </Container>
  );
};
export default ProductList;
