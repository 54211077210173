import React, { useEffect, useState } from "react";
import styles from "./OurEpisodes.module.css";
import { useFetchData } from "../../../hooks/useGetEpisodes";
import EpisodeCards from "./EpisodeCards";
import Pagination from "./Pagination";
import { Spinner } from "react-bootstrap";

const OurEpisodes = () => {
  const { data: responseData, isLoading, error, getEpisodes } = useFetchData();
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(2);

  useEffect(() => {
    getEpisodes();
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
      <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
    </div>
    )
  }

  if (error) {
    return <p className="text-center">Error: {error.message}</p>;
  }

  const products = responseData?.data || [];

  if (!Array.isArray(products) || products.length === 0) {
    return <p className="text-center">No episodes available.</p>;
  }

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecord = products.slice(indexOfFirstRecord, indexOfLastRecord);
  const npages = Math.ceil(products.length / recordsPerPage);
  console.log(currentRecord);

  return (
    <div className={styles.episodes_container}>
      <div className={styles.text_layout}>
        <p className={`text-center text-black fs-1 fw-medium font-family-Poppins text-capitalize m-0 px-3 py-3 ${styles}`}>
          Our episodes
        </p>
        <div className={styles.content_text_layout}>
          <p className={styles.content_text}>
            Our contents are broken into seasons which are further broken down
            into several episodes of amazing videos.
          </p>
        </div>
      </div>
      <div className={styles.video_container}>
        {currentRecord.map((episode, index) => (
          <EpisodeCards episode={episode} key={index} />
        ))}
      </div>
      <div className="py-5">
        <Pagination
          npages={npages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default OurEpisodes;
